<template>
  <div id="app" class="page-container">
    <div class="navbar">
      <div class="navbar-title">零金UI组件库</div>
      <div class="navbar-content">&nbsp;</div>
      <div class="navbar-right">
        <a href="#"><img src="https://cdn.ik47.com/github.svg" alt="" class="navbar-github"></a>
      </div>
    </div>
    <div class="page-content">
      <div class="sidebar">
        <div class="menu-group"
          v-for="menuItem in Object.keys(menu)"
          :key="menuItem"
        >
          <div class="menu-group-title">
            {{ menu[menuItem].showName }}
          </div>
          <div class="menu-list">
            <div class="menu-item"
              v-for="subMenu in menu[menuItem].routes"
              :key="subMenu.path"
              @click.prevent.stop="goMenu(subMenu.path)"
            >
              <a>{{ subMenu.showName }}</a>
            </div>
          </div>
        </div>
      </div>
      <router-view class="page-view"/>
      <div class="mobile-iframe">
        <div class="mobile-simulator">
          <iframe
            ref="simulator"
            src="https://2k71-public.oss-cn-qingdao.aliyuncs.com/mobile/index.html"
            frameborder="0"
            style="width: 100%; height: 100%;"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Menu from '../../../examples/menu'

export default {
  data () {
    return {
      menu: Menu
    }
  },
  created () {
    window.addEventListener('message', this.receiveMessage, false)
  },
  mounted () {
    this.$refs.simulator.onload = () => {
      this.$refs.simulator.contentWindow.postMessage(JSON.stringify({
        type: 'clickMenu',
        data: this.$route.path
      }), '*')
    }
  },
  methods: {
    goMenu (path) {
      if (path === this.$route.path) {
        return
      }
      this.$router.push(path)
      this.$refs.simulator.contentWindow.postMessage(JSON.stringify({
        type: 'clickMenu',
        data: path
      }), '*')
    },
    receiveMessage (event) {
      console.log('i am 8080', event)
    }
  }
}
</script>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
}

body {
  background-color: #f7f8fa;
}

::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.page-container {
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;

  .navbar {
    flex-shrink: 0;
    width: 100%;
    height: 62px;
    background-color: #50C1E9;
    display: flex;
    align-items: center;
    color: #fff;
    box-shadow: 0 3px 8px #ebedf0;
  }

  .navbar-title {
    width: 220px;
    padding-left: 24px;
    font-size: 20px;
  }

  .navbar-content {
    flex: 1;
  }

  .navbar-right {
    width: 220px;
    display: flex;
    align-items: center;
    text-align: right;
    justify-content: flex-end;
    padding-right: 24px;

    img {
      width: 26px;
      height: 26px;
      border-radius: 50%;
    }
  }

  .page-content {
    flex: 1;
    display: flex;
    overflow: hidden;
  }

  .sidebar {
    flex-shrink: 0;
    width: 220px;
    padding: 20px 0;
    min-height: 100%;
    overflow-y: scroll;
    box-shadow: 0 8px 12px #ebedf0;
    background-color: #fff;
  }

  .page-view {
    flex: 1;
    overflow: auto;
    padding: 16px;
  }

  .mobile-iframe {
    position: relative;
    width: 400px;
    height: 100vh;
    z-index: 1;
    box-sizing: border-box;
  }

  .mobile-simulator {
    position: fixed;
    top: 88px;
    right: 32px;
    width: 360px;
    height: 640px;
    box-shadow: #ebedf0 0 4px 12px;
    overflow: hidden;
    border-radius: 8px;
  }

  .menu-group {
    padding: 8px 0 8px 30px;
  }

  .menu-group-title {
    font-size: 15px;
    font-weight: bold;
    line-height: 28px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .menu-item {
    height: 40px;
    font-size: 13px;
    color: #455a64;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &:hover {
      cursor: pointer;
    }

    &:active {
      background: #f7f8fa;
      margin: 0 -24px;
      padding: 0 24px;
    }
  }
}
</style>
