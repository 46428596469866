module.exports = {
  basicRoute: {
    showName: '基础路由',
    routes: [
      {
        path: '/button',
        name: 'Button',
        component: 'button',
        showName: 'Button 按钮',
      },
      {
        path: '/popup',
        name: 'Popup',
        component: 'popup',
        showName: 'Popup 弹出层',
      },
      {
        path: '/toast',
        name: 'Toast',
        component: 'toast',
        showName: 'Toast 吐司',
      },
      {
        path: '/agreement',
        name: 'Agreement',
        component: 'agreement',
        showName: 'Agreement 协议',
      },
    ]
  },
  formRoute: {
    showName: '表单组件',
    routes: [
      {
        path: '/input',
        name: 'Input',
        component: 'input',
        showName: 'Input 输入框',
      },
      {
        path: '/switch',
        name: 'Switch',
        component: 'switch',
        showName: 'Switch 开关',
      },
      {
        path: '/picker',
        name: 'Picker',
        component: 'picker',
        showName: 'Picker 选择器',
      },
      {
        path: '/number-keyboard',
        name: 'NumberKeyboard',
        component: 'numberKeyboard',
        showName: 'NumberKeyboard 数字键盘',
      },
    ]
  },
  feedBackRoute: {
    showName: '反馈组件',
    routes:  [
      {
        path: '/dialog',
        name: 'Dialog',
        component: 'dialog',
        showName: 'Dialog 弹出框',
      },
      {
        path: '/action-sheet',
        name: 'actionSheet',
        component: 'actionSheet',
        showName: 'ActionSheet 动作面板',
      },
      {
        path: '/loading',
        name: 'Loading',
        component: 'loading',
        showName: 'Loading 加载中',
      },
      {
        path: '/notify',
        name: 'Notify',
        component: 'notify',
        showName: 'Notify 提示',
      },
      {
        path: '/swipe-cell',
        name: 'SwipeCell',
        component: 'swipeCell',
        showName: 'SwipeCell 滑动行',
      },
      {
        path: '/pull-refresh',
        name: 'PullRefresh',
        component: 'pullRefresh',
        showName: 'PullRefresh 下拉刷新',
      },
    ]
  },
  showRoute: {
    showName: '展示组件',
    routes: [
      {
        path: '/icon',
        name: 'Icon',
        component: 'icon',
        showName: 'Icon 图标',
      },
      {
        path: '/tips',
        name: 'Tips',
        component: 'tips',
        showName: 'Tips 小贴士',
      },
      {
        path: '/notice-bar',
        name: 'NoticeBar',
        component: 'noticeBar',
        showName: 'NoticeBar 通知栏',
      },
      {
        path: '/lazyload',
        name: 'Lazyload',
        component: 'lazyload',
        showName: 'Lazyload 懒加载',
      },
      {
        path: '/tabs',
        name: 'Tabs',
        component: 'tabs',
        showName: 'Tabs 标签页',
      },
      {
        path: '/swipe',
        name: 'Swipe',
        component: 'swipe',
        showName: 'Swipe 轮播',
      },
      {
        path: '/slider',
        name: 'Slider',
        component: 'slider',
        showName: 'Slider 滑块',
      },
      {
        path: '/circle',
        name: 'Circle',
        component: 'circle',
        showName: 'Circle 环形进度条',
      },
    ]
  }
}
